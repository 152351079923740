<template>
    <div class="full-page">
        <form>
            <card>
                <div class="row">
                    <div class="col-12 pb-5">
                        <h2>Application to submit your new video</h2>
                    </div>
                    <div class="col-12">
                        <p>Hey!</p>
                        <p>You are in the right place to submit your new video.</p>
                        <p>If you haven’t released your new video on your channel yet, submit it to us <a target="_blank" href="/video-submission-bonus">and get an increased bonus offer</a> for the exclusive license of your video!</p>
                        <p>After your submission, within 3 business days, you will receive a response from our team about whether your video is approved for an exclusive license.</p>
                        <p><strong>IMPORTANT:</strong> <u>this offer is not available if your video is already released on any of your channels.</u></p>
                        <p>Here are some tips on how to make your video approvable:</p>
                        <ul class="list-style-type">
                            <li><i class="fas fa-check"></i> Video should be longer than 4 minutes and filmed at least in full HD (1080p).</li>
                            <li><i class="fas fa-check"></i> Always aim for a clean background, tidy hands and good lighting.</li>
                            <li><i class="fas fa-check"></i> It’s important to show the final result from at least 2 angles.</li>
                            <li><i class="fas fa-check"></i> Do your best not to skip important parts of the process and film them from different angles.</li>
                            <li><i class="fas fa-check"></i> Adding close-ups for small parts is a great idea.</li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <spinner v-model="loading"></spinner>

                        <div class="form-group mb-2">
                            <label class="form-control-label">Pick the categories that best describe the work you create</label>
                            <multiselect
                                v-model="video.style"
                                :options="formData.styles"
                                label="name"
                                track-by="value"
                                placeholder="Choose..."
                                :class="[{'is-invalid': errors.style.message}]"
                            ></multiselect>
                            <div class="invalid-feedback text-danger"
                                 v-if="errors.style.message"
                                 v-text="errors.style.message"
                            ></div>
                        </div>

                        <div class="form-group mb-2">
                            <label class="form-control-label">Pick the tags that best describe your videos</label>
                            <multiselect
                                v-model="video.tags"
                                :options="styleTags"
                                label="name"
                                track-by="name"
                                placeholder="Choose..."
                                :searchable="true"
                                :multiple="true"
                                :class="[{'is-invalid': errors.tags.message}]"
                            ></multiselect>
                            <div class="invalid-feedback text-danger"
                                 v-if="errors.tags.message"
                                 v-text="errors.tags.message"
                            ></div>
                        </div>

                        <base-input
                            v-model="video.link"
                            :label="'A link to your video (e.g. Youtube unlisted video)'"
                            placeholder="Youtube, Instagram or TikTok"
                            :input-classes="'form-control'"
                            :class="'form-group mb-2'"
                            :error="errors.link.message"
                        ></base-input>

                        <div class="row mb-2">
                            <div class="col-12">
                                <base-checkbox :error="errors.is_owner.message"
                                               v-model="video.isOwner"
                                               class="custom-control-alternative">
                                    <span class="text-muted">
                                         I am legal and beneficial owner (creator) of this content
                                    </span>
                                </base-checkbox>
                            </div>
                            <div class="col-12 mt-1">
                                <base-checkbox :error="errors.not_published.message"
                                               v-model="video.notPublished"
                                               class="custom-control-alternative">
                                    <span class="text-muted">
                                         I confirm that this video is not yet published publicly
                                    </span>
                                </base-checkbox>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-12">
                                <p class="text-muted font-weight-500">
                                    Important note: As a Creator, you retain the ownership of any intellectual rights you hold.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <b-alert v-model="errors.form.show" v-text="errors.form.message" variant="danger"></b-alert>
                <b-alert v-model="success" variant="success">
                    We received your video. Thank you for submitting! We will get back to you in 3 business days.
                </b-alert>
                <div class="row mt-2">
                    <div class="col-lg-12 text-right">
                        <button type="button" @click="submitVideo" class="btn btn-primary mt-3">Submit</button>
                    </div>
                </div>
            </card>
        </form>
    </div>
</template>

<script>

import {BAlert} from 'bootstrap-vue';
import ProcessError from '@/mixins/ProcessError';
import Spinner from '@/components/Spinner.vue'

export default {
    name: 'submit-video',
    components: {BAlert, Spinner},
    mixins: [ProcessError],
    data() {
        return {
            loading: false,
            success: false,
            video: {
                link: null,
                style: null,
                tags: null,
                isOwner: false,
                notPublished: false,
            },
            errors: {
                form: {message: '', show: false},
                link: {message: ''},
                style: {message: ''},
                tags: {message: ''},
                is_owner: {message: ''},
                not_published: {message: ''},
            },
            formData: {
                styles: [
                    {value: 'beauty', name: 'Beauty'},
                    {value: 'diy', name: 'DIY'},
                ],
                tags: {
                    beauty: [
                        {name: 'Hair dye'},
                        {name: 'Hair cut'},
                        {name: 'Hair style'},
                        {name: 'Makeup'},
                        {name: 'Eye makeup'},
                        {name: 'Brows'},
                        {name: 'Lips'},
                        {name: 'Eyelashes'},
                        {name: 'Body treatments'},
                        {name: 'Face treatments'},
                        {name: 'Hair removal'},
                        {name: 'Manicure'},
                        {name: 'Pedicure'},
                        {name: 'Nail art'},
                        {name: 'Home decor'},
                        {name: 'Jewelry'},
                        {name: 'Art'},
                        {name: 'Food'},
                        {name: 'Gardening'},
                    ],
                    diy: [
                        {name: 'Epoxy'},
                        {name: 'Metal'},
                        {name: 'Wood'},
                        {name: 'Concrete/cement'},
                        {name: 'Glass'},
                        {name: 'Stone'},
                        {name: 'Sticks'},
                        {name: 'Paper'},
                        {name: 'Cardboard'},
                        {name: 'Sand'},
                        {name: 'Plastic'},
                        {name: 'Leather'},
                        {name: 'Plywood'},
                        {name: 'Pallet'},
                        {name: 'Scraps'},
                        {name: 'Bamboo'},
                        {name: 'Foil'},
                        {name: 'Clay'},
                        {name: 'Aluminum'},
                        {name: 'Plaster'},
                        {name: 'Carbon'},
                        {name: 'Burl'},
                        {name: 'Rusted'},
                        {name: 'Crystal'},
                        {name: 'Gemstone'},
                        {name: 'PVC Pipe'},
                        {name: 'Titanium'},
                        {name: 'Meteorite'},
                        {name: 'Wheat'},
                        {name: 'Acrylic'},
                        {name: 'Crayon'},
                        {name: 'Amber'},
                        {name: 'Granite'},
                        {name: 'Gelatin'},
                        {name: 'Coral'},
                        {name: 'Foam'},
                        {name: 'Burning'},
                        {name: 'Woodturning'},
                        {name: 'Cleaning'},
                        {name: 'Constructing'},
                        {name: 'Sculpting'},
                        {name: 'Knitting'},
                        {name: 'Animating'},
                        {name: 'Wrapping'},
                        {name: 'Restoring'},
                        {name: 'Painting'},
                        {name: 'Cutting'},
                        {name: 'Melting'},
                        {name: 'Forging'},
                        {name: 'Bending'},
                        {name: 'Engraving'},
                        {name: 'Masking'},
                        {name: 'Sealing'},
                        {name: 'Plumbing'},
                        {name: 'Floating'},
                        {name: 'Folding'},
                        {name: 'Gardening'},
                        {name: 'Building'},
                        {name: 'Tufting'},
                        {name: 'Lifting'},
                        {name: 'Sewing'},
                        {name: 'Washing'},
                        {name: 'Repairing'},
                        {name: 'Etching'},
                        {name: 'Welding'},
                        {name: 'Printing'},
                        {name: 'Carving'},
                        {name: 'Converting'},
                    ],
                }
            },
        }
    },
    computed: {
        styleTags() {
            return this.video.style ? this.formData.tags[this.video.style.value] || [] : []
        },
    },
    methods: {
        closeModal() {
            this.$bvModal.hide('submit-video');
        },
        submitVideo() {
            this.success = false;
            this.resetErrors();
            this.loading = true;
            this.axios.post('./submit-video.json', {
                link: this.video.link,
                style: this.video.style ? this.video.style.name : null,
                tags: this.video.tags,
                is_owner: this.video.isOwner,
                not_published: this.video.notPublished,
            }).then(() => {
                this.success = true;
                this.loading = false;

            }).catch(error => {
                this.processError(error);
            });
        },
    }
};
</script>

<style lang="scss" scoped>
.list-style-type {
    list-style-type: none;
}
</style>
